import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const SpotlightProjet = ({ block }) => {
  return (
    <div className="container mx-auto py-12">
      <div className="grid grid-cols-12 max-w-[1920px] mx-auto mt-6 lg:mt-12">
        <div className="illustration col-span-7">
          <GatsbyImage
            image={block.illustration.gatsbyImageData}
            alt={`illustration ${block.titre}`}
          />
        </div>
        <div className="description col-span-5">
          <h2 className="font-archia text-5xl font-bold text-visuall-gris-logo pl-12">
            {block.titre}
          </h2>
          <div
            className="content text-visuall-gris-logo font-display text-2xl pl-12 mt-4"
            dangerouslySetInnerHTML={{ __html: block.description }}
          />
          {block.category && (
            <div className="pl-12 mt-5">
              {block.category.map(c => {
                return (
                  <Link
                    key={c.slug}
                    to={c.slug}
                    className="flex items-center font-display text-2xl text-visuall-gris-logo"
                  >
                    <div
                      className="h-1 w-5 mr-2"
                      style={{ background: c.color.hex }}
                    />
                    {c.title}
                  </Link>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

SpotlightProjet.propTypes = {}

export default SpotlightProjet
